import { useMutation } from 'react-query';
import axios from '../config/axios.js';
import logger from '../utils/logger.js';
import resizeAndCompressImage from '../utils/resizeimg.js'; // Add this import

export const useProfilePicture = () => {
  return useMutation(async ({ file, userId }) => {
    try {
      // Step 0: Optimize the image first (following Azure best practices)
      const compressedFile = await resizeAndCompressImage(file, {
        maxSizeKB: 500, // Smaller size for profile pics (500KB is plenty)
        maxWidth: 500, // Standard profile pic dimensions
        maxHeight: 500, // Square aspect ratio for profiles
        quality: 0.85, // Slightly higher quality for profile pictures
      });

      logger.log(
        'Image compressed from',
        file.size,
        'to',
        compressedFile.size,
        'bytes'
      );

      // 1. Get SAS URL (use compressed file name)
      const cleanFileName = compressedFile.name.replace(/\s+/g, ''); // Remove spaces
      const sasResponse = await axios.get('/api/profilepictures', {
        params: {
          fileName: cleanFileName,
          userId: userId,
        },
      });

      if (!sasResponse.data?.sasUrl) {
        throw new Error('Invalid SAS URL received');
      }

      // 2. Upload compressed file to blob storage
      const uploadResponse = await fetch(sasResponse.data.sasUrl, {
        method: 'PUT',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': compressedFile.type || 'image/jpeg',
        },
        body: compressedFile, // Use the optimized file instead of the original
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.statusText}`);
      }

      // 3. Use CDN URL from response or construct fallback
      const cdnUrl =
        sasResponse.data.cdnUrl ||
        `https://plancooblobcdn.azureedge.net/profile-pictures/${sasResponse.data.blobName}`;

      logger.log('Using CDN URL:', cdnUrl);
      return cdnUrl;
    } catch (error) {
      console.error('Profile picture upload error:', error);
      throw error;
    }
  });
};
