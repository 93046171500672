import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../../UserContext.js';
import axiosInstance from '../../config/axios.js';
import { QueryKeys } from '../../constants/queryKeys.js';
import './CompanyForm.css';
import CompanyLayout from './CompanyLayout.js';
import { useTranslation } from 'react-i18next'; // Add this import
import { BUSINESS_AREA_KEYS } from '../../utils/businessAreas.js';

const CreateCompanyForm = () => {
  const [companyName, setCompanyName] = useState('');
  const [BusinessArea, setBusinessArea] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [error, setError] = useState('');
  const { user } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation(); // Add this line

  const createCompanyMutation = useMutation(
    async (companyData) => {
      // Use a hardcoded endpoint path instead of the environment variable to avoid space issues
      const apiEndpoint = '/api/CreateCompany';

      console.log('Sending request to:', apiEndpoint);

      const response = await axiosInstance.post(apiEndpoint, companyData, {
        headers: {
          'x-user-uid': user.uid,
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // Update queries
        queryClient.invalidateQueries(QueryKeys.companies.all);

        // Invalidate AND update user data to ensure updated UI
        await queryClient.invalidateQueries(['userData', user?.uid]);
        await queryClient.refetchQueries(['userData', user?.uid], {
          active: true,
        });

        console.log('Create company response:', data);

        // Navigate to company profile instead of home
        if (data && data.companyId) {
          toast.success(t('company_created'));
          navigate(`/companies/${data.companyId}`);
        } else {
          toast.success(t('company_created'));
          navigate('/home');
        }
      },
      onError: (error) => {
        console.error('Error creating company:', error);
        console.log(
          'Request data:',
          JSON.stringify({
            name: companyName.trim(),
            ownerUid: user.uid,
            BusinessArea: BusinessArea,
            IsPublic: isPublic,
          })
        );

        if (error.response) {
          console.log('Response status:', error.response.status);
          console.log('Response data:', error.response.data);
        }

        setError(
          error.response?.data?.message ||
            t(
              'company_creation_error',
              'Could not create company. Try again later.'
            )
        );
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    // Validering av obligatoriske felt
    if (!companyName.trim()) {
      setError(t('company_name_required', 'Selskapsnavn er påkrevd'));
      return;
    }

    // Validering av bransje
    if (!BusinessArea) {
      setError(t('business_area_required', 'Bransje er påkrevd'));
      return;
    }

    // Oppdater casen på feltene for å samsvare med backend-forventninger
    const companyData = {
      name: companyName.trim(),
      ownerUid: user.uid,
      BusinessArea: BusinessArea,
      IsPublic: isPublic,
    };

    console.log('Sending company data:', companyData);
    createCompanyMutation.mutate(companyData);
  };

  return (
    <CompanyLayout title={t('create_new_company', 'Opprett nytt selskap')}>
      <div className="create-company-container">
        {error && (
          <div className="alert alert-danger">
            <i className="fas fa-exclamation-circle"></i> {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="company-form">
          <div className="form-group">
            <label htmlFor="companyName">
              {t('company_name', 'Selskapsnavn')} *
            </label>
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className={`form-control ${!companyName.trim() && error ? 'is-invalid' : ''}`}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="BusinessArea">
              {t('business_area', 'Bransje')} *
            </label>
            <select
              id="BusinessArea"
              value={BusinessArea}
              onChange={(e) => setBusinessArea(e.target.value)}
              className={`form-control ${!BusinessArea && error ? 'is-invalid' : ''}`}
              required
            >
              <option value="">
                {t('select_business_area', 'Velg bransje')}
              </option>
              {BUSINESS_AREA_KEYS.map((areaKey) => (
                <option key={areaKey} value={t(areaKey)}>
                  {t(areaKey)}
                </option>
              ))}
            </select>
            {!BusinessArea && error && (
              <div className="invalid-feedback">
                {t('business_area_required', 'Bransje må velges')}
              </div>
            )}
          </div>

          <div className="form-group toggle-group">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
              />
              <span className="toggle-slider"></span>
            </label>
            <span>
              {t('make_company_public', 'Gjør selskapet offentlig synlig')}
            </span>
          </div>

          <div className="form-actions">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => navigate(-1)}
            >
              <i className="fas fa-times"></i> {t('cancel', 'Avbryt')}
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={createCompanyMutation.isLoading}
            >
              {createCompanyMutation.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i>{' '}
                  {t('creating', 'Oppretter...')}
                </>
              ) : (
                <>
                  <i className="fas fa-plus-circle"></i>{' '}
                  {t('create_company', 'Opprett selskap')}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </CompanyLayout>
  );
};

export default CreateCompanyForm;
