import { useMutation, useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../config/axios.js';
import { QueryKeys } from '../constants/queryKeys.js';

// Add the missing useCompany hook
export const useCompany = (companyId, options = {}) => {
  return useQuery(
    QueryKeys.companies.detail(companyId),
    async () => {
      if (!companyId) return null;
      const response = await axiosInstance.get(`/api/GetCompany/${companyId}`);
      return response.data;
    },
    {
      enabled: !!companyId,
      staleTime: 5 * 60 * 1000, // 5 minutes
      ...options,
    }
  );
};

// Enhanced useCreateProject hook to support initial tasks
export const useCreateProject = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (projectData) => {
      // If initialTasks is provided, include it in the request
      const { initialTasks, ...projectDetails } = projectData;

      const response = await axiosInstance.post('/api/CreateProject', {
        ...projectDetails,
        initialTasks: initialTasks || [], // Send initialTasks to the API
      });

      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        // Invalidate relevant queries
        queryClient.invalidateQueries(QueryKeys.projects.list);
        queryClient.invalidateQueries(QueryKeys.tasks.list);

        // Get companyId from the variables (project data)
        const { companyId } = variables;

        // IMPORTANT: Invalidate the company-specific projects query
        if (companyId) {
          queryClient.invalidateQueries(
            QueryKeys.projects.byCompany(companyId)
          );
        }

        // If we received a projectId, also invalidate project-specific tasks
        if (data && data.projectId) {
          queryClient.invalidateQueries(
            QueryKeys.tasks.byProject(data.projectId)
          );
          queryClient.invalidateQueries(
            QueryKeys.projects.detail(data.projectId)
          );
        }
      },
    }
  );
};

// Get projects for a company
export const useCompanyProjects = (companyId, options = {}) => {
  return useQuery(
    QueryKeys.projects.byCompany(companyId),
    async () => {
      if (!companyId) return { projects: [] };

      // Use the correct endpoint for fetching projects
      const response = await axiosInstance.get(
        `/api/GetProjectsByCompany/${companyId}`
      );

      // Return properly formatted data matching what ProjectGallery expects
      return {
        projects: response.data?.projects || [],
        total: response.data?.total || 0,
      };
    },
    {
      enabled: !!companyId,
      staleTime: 5 * 60 * 1000,
      ...options,
    }
  );
};

// Get a single project
export const useProject = (projectId, options = {}) => {
  return useQuery(
    QueryKeys.projects.detail(projectId),
    async () => {
      if (!projectId) return null;
      const response = await axiosInstance.get(`/api/projects/${projectId}`);
      return response.data;
    },
    {
      enabled: !!projectId,
      staleTime: 5 * 60 * 1000, // 5 minutes
      ...options,
    }
  );
};

// Delete project mutation
export const useDeleteProject = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (projectId) => {
      const response = await axiosInstance.delete(
        `/api/DeleteProject/${projectId}`
      );
      return response.data;
    },
    {
      onSuccess: (data, projectId) => {
        // Get company ID from the cache if not provided in response
        let companyId;

        if (data?.companyId) {
          companyId = data.companyId;
        } else {
          // Try to get companyId from project cache
          const project = queryClient.getQueryData(
            QueryKeys.projects.detail(projectId)
          );
          if (project?.companyId) {
            companyId = project.companyId;
          }
        }

        // Invalidate relevant queries
        if (companyId) {
          queryClient.invalidateQueries(
            QueryKeys.projects.byCompany(companyId)
          );
        }

        // Always invalidate these
        queryClient.invalidateQueries(QueryKeys.projects.list);
        queryClient.invalidateQueries([QueryKeys.projects.detail(projectId)]);
        queryClient.invalidateQueries([QueryKeys.tasks.byProject(projectId)]);
      },
    }
  );
};
