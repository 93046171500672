import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SearchUsers from '../Portal/Social/SearchUsers.js';
import Sidebar from '../Portal/Sidebar/Sidebar.js';
import './CompanyPage.css';
import axiosInstance from '../../config/axios.js';
import { useUserData } from '../../hooks/useUserProfile.js';
import {
  FaBuilding,
  FaUserPlus,
  FaUserCheck,
  FaUsers,
  FaSave,
} from 'react-icons/fa';
import logger from '../../utils/logger.js';

const API_ADD_COMPANY_MEMBER =
  process.env.REACT_APP_ADD_COMPANY_MEMBER || '/api/addcompanymember';
const API_CREATE_COMPANY =
  process.env.REACT_APP_CREATE_COMPANY || '/api/CreateCompany';

const CompanyPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [error, setError] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [members, setMembers] = useState([]);

  const { data: authUser } = useQuery(['auth', 'user']);

  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [licenseInfo, setLicenseInfo] = useState({
    total: 0,
    used: 0,
    available: 0,
  });
  const [licensedUsers, setLicensedUsers] = useState([]);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserName, setNewUserName] = useState('');

  const effectiveUid = authUser?.uid;
  const { data: userProfileData } = useUserData(effectiveUid);

  const companyId = userProfileData?.companyId || authUser?.companyId || null;

  const currentUserUid = effectiveUid || userProfileData?.uid;

  const canManageCompany =
    userProfileData?.role === 'Admin' ||
    members.some((m) => m.userUid === currentUserUid && m.role === 'Admin');

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (
        !process.env.REACT_APP_ADD_COMPANY_MEMBER ||
        !process.env.REACT_APP_CREATE_COMPANY
      ) {
        console.error('API endpoints not properly configured');
      }
    }
  }, []);

  useEffect(() => {
    if (companyId) {
      const fetchMembers = async () => {
        try {
          const response = await axiosInstance.get(
            `/api/companymembers/${companyId}`
          );

          const transformedMembers = response.data.map((member) => ({
            userUid: member.uid,
            name: member.name,
            email: member.email,
            role:
              member.role ||
              (member.memberType === 'ADMIN' ? 'Admin' : 'Member'),
          }));

          setMembers(transformedMembers);
        } catch (error) {
          console.error('Failed to fetch company members:', error);
        }
      };

      fetchMembers();
    }
  }, [companyId]);

  useQuery(
    ['companyLicense', companyId],
    async () => {
      if (!companyId) return null;

      try {
        const response = await axiosInstance.get(`/api/licenses/${companyId}`);

        setLicenseInfo({
          total: response.data.licenseCount || 0,
          used: response.data.usedLicenses || 0,
          available:
            (response.data.licenseCount || 0) -
            (response.data.usedLicenses || 0),
        });

        setLicensedUsers(response.data.licensedUsers || []);

        return response.data;
      } catch (error) {
        console.error('Failed to fetch license information:', error);
        throw error;
      }
    },
    {
      enabled: !!companyId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 1,
    }
  );

  const createCompanyMutation = useMutation(
    (companyData) => axiosInstance.post(API_CREATE_COMPANY, companyData),
    {
      onSuccess: (response) => {
        const newCompanyId = response?.data?.companyId;
        const newCompanyName = companyName;

        if (newCompanyId) {
          if (process.env.NODE_ENV === 'development') {
            logger.log('Company created successfully');
          }

          queryClient.setQueryData(
            [`userProfile-${currentUserUid}`],
            (oldData) => {
              if (!oldData)
                return { companyId: newCompanyId, companyName: newCompanyName };
              return {
                ...oldData,
                companyId: newCompanyId,
                companyName: newCompanyName,
              };
            }
          );

          axiosInstance
            .get(`/api/companymembers/${newCompanyId}`)
            .then((response) => {
              const transformedMembers = response.data.map((member) => ({
                userUid: member.uid,
                name: member.name,
                email: member.email,
                role:
                  member.role ||
                  (member.memberType === 'ADMIN' ? 'Admin' : 'Member'),
              }));
              setMembers(transformedMembers);
            })
            .catch((error) =>
              console.error('Failed to fetch company members:', error)
            );

          setTimeout(() => {
            queryClient.invalidateQueries([`userProfile-${currentUserUid}`]);
          }, 100);
        }

        toast.success('Company created successfully');
        setCompanyName('');
        setError('');
      },
      onError: (error) => {
        console.error('Create company error:', error);
        const errorMessage =
          error?.response?.data || 'Failed to create company';
        setError(errorMessage);
      },
    }
  );

  const handleCreateCompany = (e) => {
    e.preventDefault();
    if (!companyName.trim() || !currentUserUid) {
      setError('Please fill in all fields.');
      return;
    }
    if (companyName.length > 100) {
      setError('Company name too long');
      return;
    }
    const companyData = {
      name: companyName,
      ownerUid: currentUserUid,
    };
    createCompanyMutation.mutate(companyData);
  };

  const handleAddUserToCompany = (user) => {
    setSelectedUsers((prev) => {
      if (!prev.find((u) => u.uid === user.uid)) {
        return [...prev, { ...user, isAdmin: false }];
      }
      return prev;
    });
  };

  const handleRemoveUserFromCompany = (userUid) => {
    setSelectedUsers((prev) => prev.filter((user) => user.uid !== userUid));
  };

  const addCompanyMembersMutation = useMutation(
    (usersToAdd) => {
      if (process.env.NODE_ENV === 'development') {
        logger.log('Sending payload to API', {
          usersCount: usersToAdd.length,
        });
      }

      return axiosInstance.post(API_ADD_COMPANY_MEMBER, {
        companyId,
        users: usersToAdd,
      });
    },
    {
      onSuccess: (response) => {
        logger.log('API response:', response.data);
        if (response?.data) {
          setMembers(response.data);
        }
        toast.success('Users added to company');
        setSelectedUsers([]);
      },
      onError: (error) => {
        console.error('Failed to add company members:', error);
        toast.error('Failed to add users to company');
      },
    }
  );

  const handleAddCompanyMembers = () => {
    if (!companyId) {
      alert('Firma er ikke opprettet ennå!');
      return;
    }

    if (selectedUsers.length === 0) {
      toast.info('No users selected to add');
      return;
    }

    const usersToAdd = selectedUsers.map((user) => ({
      userUid: user.uid,
      role: user.isAdmin ? 'Admin' : 'User',
    }));
    addCompanyMembersMutation.mutate(usersToAdd);
  };

  const handleAdminCheckboxChange = (userUid) => {
    setSelectedUsers((prev) =>
      prev.map((user) =>
        user.uid === userUid ? { ...user, isAdmin: !user.isAdmin } : user
      )
    );
  };

  const addLicensedUser = useMutation(
    async () => {
      if (!newUserEmail || !newUserName || licenseInfo.available <= 0) {
        throw new Error(
          !newUserEmail || !newUserName
            ? 'Navn og e-post er påkrevd'
            : 'Ingen lisenser tilgjengelig'
        );
      }

      const response = await axiosInstance.post('/api/addLicensedUser', {
        companyId,
        email: newUserEmail,
        name: newUserName,
      });

      return response.data;
    },
    {
      onSuccess: () => {
        toast.success('Bruker lagt til');
        setNewUserEmail('');
        setNewUserName('');
        setShowLicenseModal(false);
        queryClient.invalidateQueries(['companyLicense', companyId]);
      },
      onError: (error) => {
        toast.error(error.message || 'Kunne ikke legge til bruker');
      },
    }
  );

  const removeLicensedUser = useMutation(
    async (userId) => {
      const response = await axiosInstance.delete(
        `/api/removeLicensedUser/${companyId}/${userId}`
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success('User removed successfully');
        queryClient.invalidateQueries(['companyLicense', companyId]);
      },
      onError: (error) => {
        console.error('Failed to remove licensed user:', error);
        toast.error('Failed to remove user');
      },
    }
  ).mutate;

  const handleAdminAction = () => {
    if (!canManageCompany) {
      toast.error('You do not have permission to perform this action');
      return;
    }

    toast.info('Admin action triggered');
  };

  const renderLicenseManagement = () => {
    if (!companyId) return null;

    return (
      <div className="license-management-section">
        <div className="license-header">
          <h3>{t('license_management') || 'Lisensadministrasjon'}</h3>
          <div className="license-stats">
            <span>
              {t('licenses_used') || 'Brukte lisenser'}:{' '}
              {licenseInfo?.used || 0} / {licenseInfo?.total || 0}
            </span>
            <span>
              {t('licenses_available') || 'Tilgjengelige lisenser'}:{' '}
              {licenseInfo?.available || 0}
            </span>
          </div>
          <button
            className="add-button"
            onClick={() => setShowLicenseModal(true)}
            disabled={(licenseInfo?.available || 0) <= 0}
          >
            {t('add_licensed_user') || 'Legg til lisensiert bruker'}
          </button>
        </div>

        <div className="licensed-users-list">
          <h4>{t('licensed_users') || 'Licensed Users'}</h4>
          {licensedUsers.length === 0 ? (
            <p>{t('no_licensed_users') || 'No licensed users added yet.'}</p>
          ) : (
            <table className="users-table">
              <thead>
                <tr>
                  <th>{t('name') || 'Name'}</th>
                  <th>{t('email') || 'Email'}</th>
                  <th>{t('status') || 'Status'}</th>
                  <th>{t('actions') || 'Actions'}</th>
                </tr>
              </thead>
              <tbody>
                {licensedUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.uid
                        ? t('active') || 'Active'
                        : t('pending') || 'Pending'}
                    </td>
                    <td>
                      <button
                        className="remove-button"
                        onClick={() => removeLicensedUser(user.id)}
                      >
                        {t('remove') || 'Remove'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {showLicenseModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>{t('add_licensed_user') || 'Add Licensed User'}</h3>
              <p>
                {t('available_licenses') || 'Available licenses'}:{' '}
                {licenseInfo.available}
              </p>

              <div className="form-group">
                <label>{t('name') || 'Name'}:</label>
                <input
                  type="text"
                  value={newUserName}
                  onChange={(e) => setNewUserName(e.target.value)}
                  placeholder={t('enter_user_name') || 'Enter user name'}
                />
              </div>

              <div className="form-group">
                <label>{t('email') || 'Email'}:</label>
                <input
                  type="email"
                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                  placeholder={t('enter_user_email') || 'Enter user email'}
                />
              </div>

              <div className="modal-actions">
                <button
                  className="cancel-button"
                  onClick={() => setShowLicenseModal(false)}
                >
                  {t('cancel') || 'Cancel'}
                </button>
                <button
                  className="add-button"
                  onClick={() => addLicensedUser.mutate()}
                  disabled={addLicensedUser.isLoading}
                >
                  {addLicensedUser.isLoading
                    ? t('adding') || 'Adding...'
                    : t('add_user') || 'Add User'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="company-container">
      <div className="sidebar">
        <Sidebar />
      </div>

      <div className="company-main-content">
        <div className="company-page">
          <h1>{t('company_page') || 'Company Management'}</h1>

          {!companyId && !authUser?.companyName && (
            <div className="company-admin-section">
              <div className="company-page-header">
                <h1>
                  <FaBuilding /> Company Setup
                </h1>
                <p className="company-page-description">
                  Create your company to start managing team members and
                  licenses.
                </p>
              </div>
              <div className="company-form CreateCompanyForm">
                <h2>Create New Company</h2>
                <form onSubmit={handleCreateCompany}>
                  <div className="company-form-group">
                    <label htmlFor="companyName">{t('company_name')}:</label>
                    <input
                      id="companyName"
                      className="company-form-control"
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required
                    />
                  </div>
                  {error && <p className="company-error-message">{error}</p>}
                  <button
                    className="company-btn company-btn-primary"
                    type="submit"
                  >
                    <FaBuilding /> {t('Create Company')}
                  </button>
                </form>
              </div>
            </div>
          )}

          {companyId && (
            <div className="company-management">
              <div className="company-page-header">
                <h1>
                  <FaBuilding />{' '}
                  {authUser?.companyName || 'Company Administration'}
                </h1>
                <p className="company-page-description">
                  Manage your company&apos;s team members and licenses.
                </p>
              </div>

              <div className="company-admin-section">
                <h2>
                  <FaUserPlus /> Add Team Members
                </h2>
                <div className="company-search-container">
                  <SearchUsers setSearchResults={setSearchResults} />
                </div>

                <div className="company-search-results company-section-grid">
                  {searchResults.length === 0 ? (
                    <p>{t('no_users_found')}</p>
                  ) : (
                    searchResults.map((user, index) => (
                      <div
                        key={index}
                        className="company-user-card company-card"
                      >
                        <div className="company-card-body">
                          <img
                            src={user.profilepicurl || 'default-avatar.png'}
                            alt={`${user.name}'s profile`}
                            className="company-user-profile-image"
                          />
                          <div className="company-user-details">
                            <h5>{user.name}</h5>
                            <p>{user.email}</p>
                            <p>{user.Company}</p>
                          </div>
                          <button
                            onClick={() => handleAddUserToCompany(user)}
                            className="company-btn company-btn-primary company-add-btn"
                          >
                            <FaUserCheck /> {t('add')}
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                <div className="company-selected-users">
                  <h3>{t('selected_users')}</h3>
                  {selectedUsers.length === 0 ? (
                    <p>No users selected yet. Search and add users above.</p>
                  ) : (
                    selectedUsers.map((user, index) => (
                      <div
                        key={index}
                        className="company-user-card company-card"
                      >
                        <div className="company-card-body">
                          <img
                            src={user.profilepicurl || 'default-avatar.png'}
                            alt={`${user.name}'s profile`}
                            className="company-user-profile-image"
                          />
                          <div className="company-user-details">
                            <h5>{user.name}</h5>
                            <div className="company-admin-checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={user.isAdmin}
                                  onChange={() =>
                                    handleAdminCheckboxChange(user.uid)
                                  }
                                />
                                Admin
                              </label>
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              handleRemoveUserFromCompany(user.uid)
                            }
                            className="company-btn company-btn-danger company-remove-btn"
                          >
                            {t('remove')}
                          </button>
                        </div>
                      </div>
                    ))
                  )}

                  {selectedUsers.length > 0 && (
                    <button
                      onClick={handleAddCompanyMembers}
                      className="company-btn company-btn-success company-save-btn"
                    >
                      <FaSave /> {t('Add to Company')}
                    </button>
                  )}
                </div>

                <div className="company-members-list">
                  <h3>
                    <FaUsers /> {t('company_members', 'Company Members')}
                  </h3>
                  {members.length === 0 ? (
                    <p>{t('no_members_yet', 'No members added yet.')}</p>
                  ) : (
                    <ul>
                      {members.map((member) => {
                        const uniqueKey =
                          member.userUid ||
                          `member-${member.email}-${Math.random()
                            .toString(36)
                            .substring(7)}`;

                        const maskedEmail = canManageCompany
                          ? member.email
                          : member.email.replace(
                              /^(.{2})(.*)(@.*)$/,
                              '$1***$3'
                            );

                        return (
                          <li key={uniqueKey}>
                            <div className="company-member-info">
                              <span className="company-member-name">
                                {member.name}
                              </span>
                              <span className="company-member-email">
                                {maskedEmail}
                              </span>
                              <span
                                className={`company-member-role ${member.role === 'Admin' ? 'admin' : ''}`}
                              >
                                {member.role}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}

          {companyId && renderLicenseManagement()}

          {canManageCompany && (
            <button onClick={handleAdminAction}>Admin Action</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyPage;
