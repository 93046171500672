/**
 * Array of translation keys for business areas
 * Used across company-related components
 */
export const BUSINESS_AREA_KEYS = [
  'business_area_construction',
  'business_area_oil_gas',
  'business_area_energy_renewable',
  'business_area_maritime_offshore',
  'business_area_industry_production',
  'business_area_technical_installation',
  'business_area_engineering_consulting',
  'business_area_it_system_integration',
  'business_area_logistics_supplier',
  'business_area_real_estate',
  'business_area_environment_sustainability',
  'business_area_public_sector',
  'business_area_private_use',
  'business_area_other',
];
