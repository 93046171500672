import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateUserData } from '../../../hooks/useUserProfile.js';
import { useProfilePicture } from '../../../hooks/useProfilePicture.js';
import { useUser } from '../../../UserContext.js';
import profilepic from '../../../assets/profilepic.png';
import './ProfileModal.css';
import logger from '../../../utils/logger.js';

const ProfileModal = ({ onClose, profileData }) => {
  const { t } = useTranslation();
  const { currentUserId } = useUser();
  const updateProfile = useUpdateUserData();

  // FIX: Store the entire result from useProfilePicture() instead of destructuring
  const uploadProfilePicture = useProfilePicture();

  // Initial form state
  const [formData, setFormData] = useState({
    name: '',
    profilepicurl: '',
  });

  const [profileImageFile, setProfileImageFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  // Populate form with existing data when available
  useEffect(() => {
    if (profileData) {
      setFormData({
        name: profileData.name || '',
        profilepicurl: profileData.profilepicurl || '',
      });
      setProfileImageUrl(profileData.profilepicurl || '');
    }
  }, [profileData]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Add this validation to your handleImageChange function
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Size validation (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        toast.error(t('file_too_large', 'Image too large (max 5MB)'));
        return;
      }

      // Type validation (ensure it's an image)
      if (!file.type.startsWith('image/')) {
        toast.error(t('invalid_file_type', 'Please select an image file'));
        return;
      }

      setProfileImageFile(file);

      // Preview the image
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImageUrl(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Update the handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // First upload the profile picture if a new one was selected
      let finalProfileUrl = profileImageUrl;
      if (profileImageFile) {
        setUploadingImage(true);
        try {
          // FIX: Use the correct method and parameter structure
          finalProfileUrl = await uploadProfilePicture.mutateAsync({
            file: profileImageFile,
            userId: currentUserId,
          });

          setProfileImageUrl(finalProfileUrl);
          setUploadingImage(false);
        } catch (error) {
          console.error('Image upload failed:', error);
          toast.error(t('image_upload_failed', 'Failed to upload image'));
          setUploadingImage(false);
          return;
        }
      }

      if (process.env.NODE_ENV !== 'production') {
        logger.log('Starting profile update with data:', {
          userId: '[REDACTED]',
          name: formData.name,
          hasProfileImage: !!finalProfileUrl,
        });
      }

      // THIS IS THE KEY CHANGE - Create properly structured data for API
      // Note that we MUST include Company even though we don't edit it
      const updatedData = {
        name: formData.name.trim(),
        Company: profileData.Company || '', // Keep existing Company value
        profilepicurl: finalProfileUrl,
      };

      // Validation
      if (!updatedData.name) {
        toast.error(t('Name is required'));
        return;
      }

      console.log('About to call updateProfile with data:', updatedData);

      try {
        // Send data in a format the API expects
        await updateProfile.mutateAsync({
          uid: currentUserId,
          // Send data flat, not nested (API needs direct access to fields)
          ...updatedData,
        });

        toast.success(t('Profile updated successfully'));
        handleClose();
      } catch (updateError) {
        console.error('Profile update call failed:', updateError);
        toast.error(
          updateError.message ||
            t('Failed to update profile', 'Failed to update profile')
        );
      }
    } catch (error) {
      console.error('Profile update process error:', error);
      toast.error(t('Failed to update profile', 'Failed to update profile'));
    }
  };

  // Handle smooth closing animation
  const handleClose = () => {
    const overlay = document.querySelector('.profile-edit-overlay');
    if (overlay) {
      overlay.classList.add('closing');

      // Wait for animation to complete before actually closing
      setTimeout(() => {
        onClose();
      }, 300); // Match the fadeOut animation duration
    } else {
      onClose();
    }
  };

  return (
    <div className="profile-edit-overlay">
      <div className="profile-edit-content profile-modal">
        <h2 className="modal-title">{t('edit_profile')}</h2>

        <div className="profile-preview">
          {profileImageUrl ? (
            <img
              src={profileImageUrl}
              alt={t('profile_picture')}
              className="profile-preview-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = profilepic;
              }}
            />
          ) : (
            <div className="profile-preview-placeholder">
              {formData.name.charAt(0) || '?'}
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="profile-form">
          <div className="form-group">
            <label htmlFor="name">{t('name')}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder={t('enter_your_name')}
            />
          </div>

          <div className="form-group file-upload">
            <label htmlFor="profileImage">{t('profile_picture')}</label>
            <div className="file-input-wrapper">
              <button
                type="button"
                className="file-input-button"
                onClick={() => document.getElementById('profileImage').click()}
              >
                {t('choose_file')}
              </button>
              <input
                type="file"
                id="profileImage"
                name="profileImage"
                accept="image/*"
                onChange={handleImageChange}
                className="file-input"
              />
              <span className="file-name">
                {profileImageFile ? profileImageFile.name : t('no_file_chosen')}
              </span>
            </div>
          </div>

          {uploadingImage && (
            <div className="upload-progress">
              <div className="progress-bar">
                <div className="progress-bar-inner"></div>
              </div>
              <p>{t('uploading_image')}</p>
            </div>
          )}

          <div className="form-actions">
            <button
              type="submit"
              className="submit-btn"
              disabled={uploadingImage || updateProfile.isLoading}
            >
              {updateProfile.isLoading ? t('saving') : t('Save')}
            </button>
            <button type="button" onClick={handleClose} className="cancel-btn">
              {t('cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Update PropTypes to remove company-related fields
ProfileModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  profileData: PropTypes.shape({
    name: PropTypes.string,
    Company: PropTypes.string, // Add Company back to prop validation
    profilepicurl: PropTypes.string,
    uid: PropTypes.string,
    // We still don't need companyId since we're not using it here
  }).isRequired,
};

export default ProfileModal;
