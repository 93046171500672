import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  useCompanyProjects,
  useDeleteProject,
} from '../../hooks/useProjects.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateProjectForm from './CreateProjectForm.js';
import LoadingPage from '../Spinner/LoadingPage.js';
import './ProjectGallery.css';
import {
  FaMobileAlt,
  FaChartBar,
  FaPlus,
  FaFolderOpen,
  FaTrashAlt,
  FaFilter,
  FaCaretDown,
  FaProjectDiagram,
} from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next'; // Add this import

const ProjectGallery = ({ companyId, canManage = false }) => {
  const { t } = useTranslation(); // Add translation hook
  const navigate = useNavigate();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const { data, isLoading, refetch } = useCompanyProjects(companyId);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [page, setPage] = useState(0);
  const deleteProjectMutation = useDeleteProject();
  const [deletingProject, setDeletingProject] = useState(null);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);

  // Extract the projects array from the data object
  const projects = data?.projects || [];
  const availableCategories = [
    ...new Set(projects.map((project) => project.businessArea).filter(Boolean)),
  ];

  // Handle project deletion
  const handleDeleteProject = (e, project) => {
    e.stopPropagation(); // Prevent navigation

    // Show delete confirmation toast
    const toastId = toast.warning(
      <div className="delete-toast">
        <h4>{t('delete_project_question')}</h4>
        <p>
          {t('delete_project_confirmation', {
            projectName: project.name,
          })}
        </p>
        <div className="toast-actions">
          <button onClick={() => toast.dismiss(toastId)} className="btn-cancel">
            {t('cancel')}
          </button>
          <button
            onClick={() => confirmDeleteProject(project.id, toastId)}
            className="btn-delete"
          >
            {t('delete')}
          </button>
        </div>
      </div>,
      {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        closeButton: false,
      }
    );
  };

  // Confirm and execute project deletion
  const confirmDeleteProject = async (projectId, toastId) => {
    toast.dismiss(toastId);
    setDeletingProject(projectId);

    try {
      // Show a temporary toast
      const loadingToastId = toast.info(t('deleting_project'), {
        autoClose: 2000, // Close after 2 seconds to maintain smooth UX
      });

      // Execute deletion with optimistic update
      await deleteProjectMutation.mutateAsync(projectId);

      // Remove the loading toast
      toast.dismiss(loadingToastId);

      // Show success toast
      toast.success(t('project_deleted_successfully'));
    } catch (error) {
      console.error('Project deletion error:', error);

      // Show more user-friendly error message based on status code
      if (error.response?.status === 403) {
        toast.error(t('project_delete_permission_denied'));
      } else {
        toast.error(
          t('project_delete_failed') +
            (error.response?.data?.error || error.message || t('unknown_error'))
        );
      }
    } finally {
      setDeletingProject(null);
    }
  };

  if (isLoading) return <LoadingPage />;

  // Filtered projects logic
  const filteredProjects =
    selectedCategory === 'all'
      ? projects
      : projects.filter((project) => project.businessArea === selectedCategory);

  const renderPaginationSteps = () => {
    const totalPages = Math.ceil(filteredProjects.length / 6);

    // Different rendering strategies based on number of pages
    if (totalPages <= 7) {
      // Show all page indicators if 7 or fewer pages
      return Array.from({ length: totalPages }, (_, i) => (
        <div
          key={i}
          className={`page-indicator ${page === i ? 'active' : ''}`}
          onClick={() => setPage(i)}
        >
          <span className="page-number">{i + 1}</span>
        </div>
      ));
    } else {
      // Show limited pages with ellipsis for many pages
      const indicators = [];

      // Always show first page
      indicators.push(
        <div
          key={0}
          className={`page-indicator ${page === 0 ? 'active' : ''}`}
          onClick={() => setPage(0)}
        >
          <span className="page-number">1</span>
        </div>
      );

      // Show ellipsis if not near the beginning
      if (page > 2) {
        indicators.push(
          <div key="dots1" className="page-dots">
            ...
          </div>
        );
      }

      // Show pages around current page
      const startPage = Math.max(1, Math.min(page - 1, totalPages - 4));
      const endPage = Math.min(startPage + 2, totalPages - 1);

      for (let i = startPage; i <= endPage; i++) {
        indicators.push(
          <div
            key={i}
            className={`page-indicator ${page === i ? 'active' : ''}`}
            onClick={() => setPage(i)}
          >
            <span className="page-number">{i + 1}</span>
          </div>
        );
      }

      // Show ellipsis if not near the end
      if (page < totalPages - 3) {
        indicators.push(
          <div key="dots2" className="page-dots">
            ...
          </div>
        );
      }

      // Always show last page
      indicators.push(
        <div
          key={totalPages - 1}
          className={`page-indicator ${page === totalPages - 1 ? 'active' : ''}`}
          onClick={() => setPage(totalPages - 1)}
        >
          <span className="page-number">{totalPages}</span>
        </div>
      );

      return indicators;
    }
  };

  return (
    <div className="project-gallery">
      {/* Updated header layout to position filter beside the new project button */}
      <div className="project-gallery-header">
        <h3 className="section-title">
          <FaProjectDiagram /> {t('projects')}
        </h3>

        <div className="header-actions">
          {canManage && !showCreateForm && (
            <button
              className="create-project-btn"
              onClick={() => setShowCreateForm(true)}
            >
              <FaPlus /> {t('new_project')}
            </button>
          )}

          {/* Filter dropdown moved inside header-actions */}
          <div className="filter-dropdown-container">
            <button
              className="filter-dropdown-toggle"
              onClick={() => setShowFilterDropdown(!showFilterDropdown)}
            >
              <FaFilter /> {t('filter')} <FaCaretDown />
              {selectedCategory !== 'all' && (
                <span className="selected-filter">{selectedCategory}</span>
              )}
            </button>

            {showFilterDropdown && (
              <div className="filter-dropdown-menu">
                <button
                  className={selectedCategory === 'all' ? 'active' : ''}
                  onClick={() => {
                    setSelectedCategory('all');
                    setShowFilterDropdown(false);
                  }}
                >
                  {t('all_projects')}
                </button>
                {availableCategories.map((category) => (
                  <button
                    key={category}
                    className={selectedCategory === category ? 'active' : ''}
                    onClick={() => {
                      setSelectedCategory(category);
                      setShowFilterDropdown(false);
                    }}
                  >
                    {category}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Render the create form when requested */}
      {showCreateForm && (
        <CreateProjectForm
          companyId={companyId}
          onClose={() => setShowCreateForm(false)}
          onSuccess={() => {
            setShowCreateForm(false);
            // Explicitly refetch to ensure fresh data
            refetch();
          }}
        />
      )}

      {isLoading ? (
        <div className="project-grid">
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <div className="project-card skeleton" key={`skeleton-${index}`}>
                <Skeleton height={180} width="100%" />
                <div className="project-info">
                  <Skeleton width="70%" height={24} />
                  <Skeleton width="40%" height={18} />
                  <Skeleton width="90%" height={12} count={2} />
                </div>
              </div>
            ))}
        </div>
      ) : projects.length === 0 && !showCreateForm ? (
        <div className="empty-projects">
          <div className="empty-projects-icon">
            <FaFolderOpen />
          </div>
          <h4>{t('no_projects_yet')}</h4>
          <p>
            {canManage
              ? t('start_by_creating_first_project')
              : t('no_projects_added_yet')}
          </p>
          {canManage && (
            <button
              className="btn btn-primary"
              onClick={() => setShowCreateForm(true)}
            >
              {t('create_first_project')}
            </button>
          )}
        </div>
      ) : (
        <div className="projects-grid">
          {filteredProjects.slice(page * 6, (page + 1) * 6).map((project) => (
            <div
              key={project.id}
              className={`project-card ${deletingProject === project.id ? 'deleting' : ''}`}
            >
              <div
                className="project-thumbnail"
                onClick={() => navigate(`/projects/${project.id}`)}
              >
                {project.thumbnailUrl ? (
                  <LazyLoadImage
                    src={project.thumbnailUrl}
                    alt={project.name}
                    effect="blur"
                    wrapperClassName="project-image-wrapper"
                    className="project-image"
                  />
                ) : (
                  <div className="placeholder-img">
                    {project.name.charAt(0).toUpperCase()}
                  </div>
                )}
                {project.businessArea && (
                  <span className="project-category-badge">
                    {project.businessArea}
                  </span>
                )}

                {/* Task Action style delete button */}
                {project.canDelete && (
                  <button
                    className="btn-icon project-delete-icon"
                    onClick={(e) => handleDeleteProject(e, project)}
                    disabled={deletingProject === project.id}
                    title={t('delete_this_project')}
                  >
                    <FaTrashAlt size={16} />
                    {deletingProject === project.id && (
                      <span className="delete-spinner"></span>
                    )}
                  </button>
                )}
              </div>
              <div
                className="project-content"
                onClick={() => navigate(`/projects/${project.id}`)}
              >
                <h4 className="project-title">{project.name}</h4>
                <p className="project-description">
                  {project.description
                    ? project.description.length > 100
                      ? `${project.description.substring(0, 100)}...`
                      : project.description
                    : t('no_description_provided')}
                </p>
              </div>
              <div className="project-actions">
                <button
                  className="btn btn-mobile"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/projects/${project.id}/mobile`);
                  }}
                >
                  <FaMobileAlt /> {t('mobile')}
                </button>
                <button
                  className="btn btn-gantt"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/projects/${project.id}/gantt`);
                  }}
                >
                  <FaChartBar /> {t('gantt')}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* New Circular Step Pagination */}
      {filteredProjects.length > 6 && (
        <div className="pagination-steps">{renderPaginationSteps()}</div>
      )}

      <ToastContainer />
    </div>
  );
};

ProjectGallery.propTypes = {
  companyId: PropTypes.number.isRequired,
  canManage: PropTypes.bool,
};

export default ProjectGallery;
